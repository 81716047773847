import React from "react";

const Privacidade = () => {
    return (
        <main className="privacy">
            <img className="logo" src={require("../components/assets/img/header/menu/ic-logo.png")} alt="Gifthy"/>
            <h2 className="page-title font-bold">Política de Dados e Privacidade</h2>
            <p className="item-paragraph">Esta política, redigida em consonância com a <span className="font-bold">Lei Geral de Proteção de Dados (Lei nº 13.709, de 14 de agosto de 2018) – LGPD –</span> descreve e esclarece as informações que processamos para viabilizar a operação da plataforma de e-commerce e Rede Gifthy. Você pode encontrar informações adicionais no campo “Configurações” do site de Internet Gifthy e Aplicativos para IOS e Android disponibilizados nas respectivas lojas.</p>

            {/* ----- 1 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">1. Quais os tipos de informações coletamos?</h3>
                <p className="item-paragraph">Para fornecer os serviços e produtos da Rede Gifthy, precisamos ter acesso e processar informações sobre você. Trabalharemos com informações pessoais e cadastrais e também (fase 2) com informações colhidas por dispositivo e parceiros.</p>
                <p className="item-paragraph">Relativo às informações pessoais e cadastrais, você terá de completar um cadastro, criando um usuário da Rede Gifthy, momento em que precisará informar seu nome, sobrenome, CPF, data de nascimento, apelido, endereço e e-mail. <span className="font-bold">Apelido</span> será como seu perfil aparecerá para você e seus contatos. Você também deverá criar uma senha, que deve ser pessoal e intransferível. Para operacionalizar o objetivo da Gifthy de facilitar a compra de presentes você terá de informar seus desejos de presentes, escolhendo para integrar uma lista pessoal os itens que gostaria de receber em suas datas especiais. Para realizar compras utilizando cartões de débito ou de crédito, no momento de realizar o pagamento você deverá informar os respectivos números aos sistemas de pagamento utilizados pela Gifthy.</p>
            </article>

            {/* ----- extra ----- */}
            <article className="item">
                <h3 className="item-title font-regular">Informações de dispositivo</h3>
                <p className="item-paragraph">A partir da fase 2 da plataforma de e-commerce e Rede Gifthy, poderemos coletar informações autorizadas por você provindas de seus dispositivos, tais como computadores, telefones celulares, tablets ou smartvs conectados à WEB que você utiliza e que fazem integração com a Rede Gifthy. Essas informações servirão para personalizar sua lista, indicando itens de acordo com seu estilo de vida. Essas informações podem incluir: informações como o sistema operacional, as versões do hardware e software, nível da bateria, força do sinal, espaço de armazenamento disponível, tipo de navegador, nomes e tipos de arquivo e de aplicativo, e plug-ins; informações sobre operações e comportamentos realizados no dispositivo, tais como se uma janela está em primeiro ou segundo plano, ou movimentos do cursor (que podem ajudar a distinguir humanos de bots); identificadores exclusivos, IDs do dispositivo e outros identificadores, como de jogos, aplicativos ou contas que você usa, e IDs de Família de Dispositivo; sinais de Bluetooth e informações sobre pontos de acesso de Wi- Fi nas proximidades, beacons e torres de celular; outras informações que você permite que nós recebamos por meio das configurações do dispositivo que você ativa, como o acesso à sua localização GPS, câmera ou fotos; informações como o nome de sua operadora móvel ou provedor de serviço de internet, idioma, fuso horário, número do celular, endereço IP, velocidade de conexão e, em alguns casos, informações sobre outros dispositivos que estão nas proximidades ou em sua rede, de forma que nós possamos fazer coisas como ajudar você a realizar o streaming de um vídeo de seucelular para sua TV; dados de cookies armazenados em seu dispositivo, inclusive configurações e IDs de cookies;</p>
                <p className="list-item">Informações de parceiros: os anunciantes, parceiros comerciais, desenvolvedores de aplicativos e publishers podem nos enviar informações que você autorizar sobre suas atividades fora da Rede Gifthy, inclusive informações sobre seu dispositivo, os sites que você acessa, as compras que faz, os anúncios que visualiza e sobre o uso que faz dos serviços deles. Por exemplo, um desenvolvedor de jogos poderia usar nossa API para nos informar quais jogos você joga, ou uma empresa poderia nos informar sobre uma compra que você fez na loja dela. Além disso, recebemos informações sobre suas ações e compras online e offline de provedores de dados de terceiros que têm autorização para nos fornecer essas informações. Tais parceiros recebem seus dados quando você acessa ou usa os serviços deles ou por meio de terceiros com os quais eles trabalham. <span className="font-bold">Exigimos que cada um desses parceiros tenha autorização legal para coletar, usar e compartilhar seus dados antes de fornecê-los para nós.</span></p>
            </article>

            {/* ----- 2 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">2. Como usamos essas informações?</h3>
                <p className="item-paragraph">Em estrita observância à LGPD, tratamos as informações que recebemos de você, de dispositivo ou de parceiros (em consonância com as escolhas feitas por você) para viabilizar o objetivo da Rede Gifthy em seu proveito, promovendo a facilitação na lembrança de datas, escolha e compra de presentes. Além disso, usamos as informações para promover a sua segurança, verificar contas e atividades, combater condutas danosas, detectar e prevenir spams e outras experiências negativas, manter a integridade de nossos produtos e serviços e promover a segurança dentro e fora da Rede Gifthy. Por exemplo, usamos os dados que temos para investigar atividades suspeitas ou violações de nossos termos ou políticas, ou para detectar quando alguém precisa de ajuda. Também usamos as informações para enviar a você comunicações de marketing, para nos comunicarmos com você sobre nossos produtos e novidades e para informar você sobre nossas políticas e termos. Além disso, usamos suas informações para responder quando você entra em contato conosco.</p>
            </article>

            {/* ----- 3 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">3. Como essas informações são compartilhadas?</h3>
                <p className="item-paragraph">Suas informações são compartilhadas das seguintes maneiras:</p>
                <p className="list-item">a) com integrantes desuaRede Gifthy: exclusivamente seu nome e sobrenome, apelido, foto, data de aniversário (idade só se você autorizar), data e descrição de eventos que você criou e itens que você escolheu e gostaria de receber. Estes dados, embora fornecidos à Gifthy, não serão compartilhados com pessoas que não fizerem parte de sua Rede Gifthy, cujo pedido de amizade você terá de aceitar;</p>
                <p className="list-item">b) dados relativos a cartões de débito e crédito: somente terão conhecimento os meios de pagamento (Ex. Mercado Pago, Pag Seguro, etc.) que você utilizará para comprar os produtos dentro da Rede Gifthy;</p>
                <p className="item-paragraph">Seus endereços não serão compartilhados com ninguém, nem mesmo com os amigos que você aceitar e que farão parte de sua Rede Gifthy.</p>
            </article>

            {/* ----- 4 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">4. Informações públicas</h3>
                <p className="item-paragraph">Para que você possa ser encontrado dentro da Rede Gifthy terão de ficar acessíveis para pesquisa seu nome, sobrenome, apelido, foto e data de aniversário, que podem ser vistas pelos demais integrantes da Rede, a fim de que possam convidar você para ser seu amigo.</p>
            </article>

            {/* ----- 5 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">5. Novo proprietário</h3>
                <p className="item-paragraph">Se a propriedade ou o controle total ou parcial de nossos Produtos ou respectivos ativos mudar, poderemos transferir suas informações para o novo proprietário.</p>
            </article>

            {/* ----- 6 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">6. Compartilhamento com Parceiros Externos</h3>
                <p className="item-paragraph">Trabalhamos com parceiros externos que nos ajudam a fornecer e a aprimorar nossos produtos. Não vendemos nenhuma de suas informações para ninguém e jamais o faremos. Também impomos fortes restrições sobre como nossos parceiros podem usar e os dados que fornecemos, sendo proibida a divulgação. Eis os tipos de terceiros com os quais compartilhamos informações: desenvolvedores de software para a Gifthy Network, anunciantes, parceiros de mensuração, fornecedores e provedores de serviços.</p>
            </article>

            {/* ----- 7 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">7. Compartilhamento para aplicação da lei ou solicitações legais</h3>
                <p className="item-paragraph">Compartilhamos informações com autoridades responsáveis pela aplicação da lei ou em resposta a solicitações legais nas circunstâncias em que forem exigidas para solucionar ou prevenir hipóteses de ato ilícito civil ou criminal.</p>
            </article>

            {/* ----- 8 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">8.Como faço para controlar ou excluir informações sobre mim?</h3>
                <p className="item-paragraph">Você tem a capacidade de acessar, retificar, portar e apagar seus dados, a qualquer tempo. Desejando alterar qualquer dado, você só precisa acessar o seu perfil, na Rede Gifthy, onde encontrará os campos editáveis. Encontrando dificuldade para praticar qualquer destas ações, você pode solicitá-las através do e-mail contato@gifthy.com.br que a equipe de suporte da Gifthy lhe auxiliará.</p>
            </article>

            {/* ----- 9 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">9. Armazenamos dados até que eles não sejam mais necessários para fornecermos nossos serviços ou até que sua conta seja excluída — o que ocorrer primeiro.</h3>
                <p className="item-paragraph">Esta é uma determinação que varia de acordo com cada caso e depende de fatores como a natureza dos dados, o motivo pelo qual são coletados e processados, e necessidades de retenção operacional ou legal relevantes. Por exemplo: há dados, especialmente de natureza fiscal, que necessitam permanecer armazenados por até cinco anos, determinação legal.</p>
            </article>

            {/* ----- 10 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">10. Quando você exclui sua conta na Rede Gifthy, nós excluímos todo o conteúdo desta conta, hipótese em que não será possível recuperar essas informações.</h3>
                <p className="item-paragraph">Para desativar sua conta, você deve enviar um e-mail com sua solicitação para o seguinte endereço: contato@githy.com.br, com o assunto “exclusão de conta”.</p>
            </article>

            {/* ----- 11 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">11. Como respondemos a requisições legais ou prevenimos danos?</h3>
                <p className="item-paragraph">A Gifthy está sempre à disposição das autoridades brasileiras para atender às suas solicitações tangentes a prevenir ilegalidades ou danos. Caso seja solicitada, compartilhará suas informações com as autoridades.</p>
            </article>

            {/* ----- 12 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">12. Como notificaremos você sobre mudanças nesta política?</h3>
                <p className="item-paragraph">Notificaremos você antes de fazer alterações nesta política e daremos a você a oportunidade de analisar a política revisada antes de decidir continuar usando nossos Produtos Serviços.</p>
            </article>

            {/* ----- 13 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">13. Aviso de privacidade do Brasil</h3>
                <p className="item-paragraph">Esta seção se aplica a atividades de tratamento de dados pessoais de acordo com as leis brasileiras e complementa esta Política de Dados.</p>
                <p className="item-paragraph">De acordo com o artigo 18, da Lei Geral de Proteção de Dados Pessoais do Brasil (LGPD), você tem o direito de acessar, retificar, solicitar a portabilidade de seus dados e apagar seus dados, além de autorizar o tratamento desses dados por nós.</p>
                <p className="item-paragraph">Em determinadas circunstâncias, você também tem o direito de contestar e restringir o tratamento de seus dados pessoais ou de revogar seu consentimento quando tratamos dados fornecidos por você com base nesse consentimento.</p>
                <p className="item-paragraph">Esta Política de Dados fornece informações sobre como compartilhamos dados com terceiros. Caso queira solicitar mais informações sobre as nossas práticas em relação aos dados, envie-nos um e-mail para contato@gifthy.com.br com o assunto “informações sobre dados”.</p>
                <p className="item-paragraph">O controlador de dados responsável por suas informações é a Gifthy Network Serviços de Intermediação de Negócios de Informática Ltda.</p>
                <p className="item-paragraph">O encarregado pelo controlador para o tratamento de seus dados pessoais, podendo aceitar reclamações e comunicações, inclusive provindas da autoridade nacional, prestar esclarecimentos e adotar providências, é o Diretor Executivo da Gifthy Network, <span className="font-bold">Paulo Antonio Pires Borges</span>, que poderá ser contatado pelo e-mail paulo@gifthy.com.br</p>
                <p className="item-paragraph">Você também tem o direito de peticionar em relação aos seus dados perante a Autoridade Nacional de Proteção de Dados (“ANPD”). Para isso, entre em contato diretamente com a ANPD.</p>
                <p className="item-paragraph">Esta seção passa a valer em conformidade com a vigência da LGPD.</p>
            </article>

            {/* ----- 14 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">14. Como entrar em contato com a Gifthy, em caso de dúvidas.</h3>
                <p className="item-paragraph">Você pode entrar em contato conosco online pelo e-mail contato@gifthy.com.br ou pelo meio físico, para:</p>
                <p className="list-item">Gifthy Network Serviços de Intermediação de Negócios de Informática Ltda.</p>
                <p className="list-item">Assunto: Privacidade de Dados</p>
                <p className="list-item">Avenida Carlos Gomes, 222, oitavo andar</p>
                <p className="list-item">Bairro Boa Vista – CEP 90.480-000</p>
                <p className="list-item">Porto Alegre – RS – Brasil</p>
            </article>

            {/* ----- 15 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">15. Redação integral do art. 18, da LGPD (direitos do titular dos dados):</h3>
                <p className="item-paragraph">Art. 18. O titular dos dados pessoais tem direito a obter do controlador, em relação aos dados do titular por ele tratados, a qualquer momento e mediante requisição:</p>
                <p className="item-paragraph">I - confirmação da existência de tratamento;</p>
                <p className="item-paragraph">II - acesso aos dados;</p>
                <p className="item-paragraph">III - correção de dados incompletos, inexatos ou desatualizados;</p>
                <p className="item-paragraph">IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto nesta Lei;</p>
                <p className="item-paragraph">V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos comercial e industrial, de acordo com a regulamentação do órgão controlador;</p>
                <p className="item-paragraph">V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</p>
                <p className="item-paragraph">VI - eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 desta Lei;</p>
                <p className="item-paragraph">VII - informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</p>
                <p className="item-paragraph">VIII - informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</p>
                <p className="item-paragraph">IX - revogação do consentimento, nos termos do § 5o do art. 8o desta Lei.</p>
                <p className="item-paragraph">§ 1o O titular dos dados pessoais tem o direito de peticionar em relação aos seus dados contra o controlador perante a autoridade nacional.</p>
                <p className="item-paragraph">§ 2o O titular pode opor-se a tratamento realizado com fundamento em uma das hipóteses de dispensa de consentimento, em caso de descumprimento ao disposto nesta Lei.§ 3o Os direitos previstos neste artigo serão exercidos mediante requerimento expresso do titular ou de representante legalmente constituído, a agente de tratamento.</p>
                <p className="item-paragraph">§ 4o Em caso de impossibilidade de adoção imediata da providência de que trata o § 3o deste artigo, o controlador enviará ao titular resposta em que poderá:</p>
                <p className="item-paragraph">I - comunicar que não é agente de tratamento dos dados e indicar, sempre que possível, o agente; ou</p>
                <p className="item-paragraph">II - indicar as razões de fato ou de direito que impedem a adoção imediata da providência.</p>
                <p className="item-paragraph">§ 5o O requerimento referido no § 3o deste artigo será atendido sem custos para o titular, nos prazos e nos termos previstos em regulamento.</p>
                <p className="item-paragraph">§ 6o O responsável deverá informar, de maneira imediata, aos agentes de tratamento com os quais tenha realizado uso compartilhado de dados a correção, a eliminação, a anonimização ou o bloqueio dos dados, para que repitam idêntico procedimento, exceto nos casos em que esta comunicação seja comprovadamente impossível ou implique esforço desproporcional.</p>
                <p className="item-paragraph">§ 7o A portabilidade dos dados pessoais a que se refere o inciso V do caput deste artigo não inclui dados que já tenham sido anonimizados pelo controlador.</p>
                <p className="item-paragraph">§ 8o O direito a que se refere o § 1o deste artigo também poderá ser exercido perante os organismos de defesa do consumidor.</p>
            </article>
        </main>
    )
}

export default Privacidade;